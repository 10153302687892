import { Suspense } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';

const AccessRoleRoutes = ({ user, redirectTo = {} }) => {
  const { orgId } = useParams();

  return user?.isRoot || (user?.orgIds ?? []).indexOf(orgId) > -1 ? (
    <Suspense>
      <Outlet />
    </Suspense>
  ) : (
    <Navigate to={redirectTo} replace />
  );
};

export default AccessRoleRoutes;
