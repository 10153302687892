import React, { useEffect, useMemo } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import SearchInput from 'components/ai-product/ai_product_search';
import BouncingDotsLoader from 'components/common/loading/bouncing-dots-loadger';
import PaginationFormControl from 'components/pagination';
import { useOrgData } from 'contexts/org-data-context';
import { useFilteredProductsQuery } from 'hooks/query-hooks';
import { useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { aiProductService } from 'services/ai-product.service';

const ComplianceActivities = ({ compliance }) => {
  const {
    state: { selectedOrgList, selectedOrg }
  } = useOrgData();

  const [itemsPerPage, setItemsPerPage] = useState(12);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedProduct, setSelectedProduct] = useState('all');
  const [selectedUser, setSelectedUser] = useState('all');
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const prId = searchParams.get('prId');
  const isActionsTab = location.pathname.includes('/actions');

  const { data: aiProducts = [] } = useFilteredProductsQuery(selectedOrgList, prId);
  const { data: risksWithActionsAndDocuments, isPending } = useQuery({
    queryKey: ['activities', prId || '', selectedOrg?._id],
    queryFn: async () => {
      const result = await aiProductService.getRisksWithActionsAndDocuments(
        prId || '',
        selectedOrg?._id
      );
      console.log('Risks with actions and documents:', result);
      return result || [];
    },
    enabled: !!selectedOrg?._id
  });

  const uniqueProducts = useMemo(() => {
    if (!risksWithActionsAndDocuments) return [];

    const productIds = new Set();
    risksWithActionsAndDocuments.forEach((product) => {
      product.risks.forEach((risk) => {
        if (risk.productId) {
          productIds.add(risk.productId);
        }
      });
    });

    return Array.from(productIds).map((id) => ({
      _id: id,
      product_name: aiProducts.find((p) => p._id === id)?.product_name || id
    }));
  }, [risksWithActionsAndDocuments, aiProducts]);
  useEffect(() => {
    setSelectedProduct('all');
    setSelectedUser('all');
    setSearchQuery('');
    setCurrentPage(1);
  }, [location.pathname]);
  const paginatedResults = useMemo(() => {
    if (!risksWithActionsAndDocuments) return [];

    const indexOfLastProduct = currentPage * itemsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;

    let allItems = risksWithActionsAndDocuments.flatMap((product) =>
      product.risks.flatMap((risk) =>
        (isActionsTab ? risk.actions : risk.documents || []).map((item) => ({
          ...item,
          productId: risk.productId,
          riskName: risk.riskName
        }))
      )
    );

    // Apply product filter
    if (selectedProduct !== 'all') {
      allItems = allItems.filter((item) => item.productId === selectedProduct);
    }

    // Apply user filter
    if (selectedUser !== 'all') {
      allItems = allItems.filter((item) => item.users?.some((user) => user._id === selectedUser));
    }

    // Apply search filter
    if (searchQuery) {
      allItems = allItems.filter(
        (item) =>
          item.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.title?.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    return allItems.slice(indexOfFirstProduct, indexOfLastProduct);
  }, [
    risksWithActionsAndDocuments,
    currentPage,
    itemsPerPage,
    location.pathname,
    selectedProduct,
    selectedUser,
    searchQuery
  ]);

  const pageCount = useMemo(() => {
    if (!risksWithActionsAndDocuments) return 0;

    const totalItems = risksWithActionsAndDocuments.flatMap((product) =>
      product.risks.flatMap((risk) => (isActionsTab ? risk.actions : risk.documents || []))
    ).length;

    return Math.ceil(totalItems / itemsPerPage);
  }, [risksWithActionsAndDocuments, itemsPerPage, location.pathname]);

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const renderedUsers = new Set();

  return (
    <React.Fragment>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ gap: 1, paddingY: 2 }}
      >
        <SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} label="Search" />
        <FormControl variant="outlined" sx={{ width: '10em' }}>
          <InputLabel id="product-select-label">Product</InputLabel>
          <Select
            labelId="product-select-label"
            sx={{ height: '2.4rem' }}
            name="product"
            value={selectedProduct}
            onChange={(e) => setSelectedProduct(e.target.value)}
            label="Product"
          >
            <MenuItem value="all">All</MenuItem>
            {uniqueProducts.map((product) => (
              <MenuItem key={product._id} value={product._id}>
                {product.product_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ width: '10em' }}>
          <InputLabel id="user-select-label">User</InputLabel>
          <Select
            labelId="user-select-label"
            sx={{ height: '2.4rem' }}
            name="user"
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            label="User"
          >
            <MenuItem value="all">All</MenuItem>
            {risksWithActionsAndDocuments?.flatMap((product) =>
              product.risks.flatMap((risk) => {
                const items = location.pathname.includes('/actions')
                  ? risk.actions || []
                  : risk.documents || [];

                return items.flatMap((item) =>
                  (item.users || [])
                    .filter((user) => {
                      if (renderedUsers.has(user._id)) {
                        return false;
                      }
                      renderedUsers.add(user._id);
                      return true;
                    })
                    .map((user) => (
                      <MenuItem key={user._id} value={user._id}>
                        {`${user.firstName} ${user.lastName}`}
                      </MenuItem>
                    ))
                );
              })
            ) || []}
          </Select>
        </FormControl>

        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <PaginationFormControl
            itemsPerPage={itemsPerPage}
            handleItemsPerPageChange={handleItemsPerPageChange}
            pageCount={pageCount}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
          />
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Org</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Risk Name</TableCell>
              <TableCell>
                {isActionsTab ? 'Action' : 'Document'}
                <br />
                Title
              </TableCell>
              <TableCell>
                {' '}
                {isActionsTab ? 'Action' : 'Document'}
                <br />
                Status
              </TableCell>
              <TableCell>
                {isActionsTab ? 'Action' : 'Document'}
                <br />
                Name
              </TableCell>
              <TableCell>Users</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isPending ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <BouncingDotsLoader />
                </TableCell>
              </TableRow>
            ) : paginatedResults.length === 0 ? (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  No actions found
                </TableCell>
              </TableRow>
            ) : (
              paginatedResults.map((action, index) => (
                <TableRow key={`${action.activityId}-${index}`}>
                  <TableCell>{selectedOrg?.name || '-'}</TableCell>
                  <TableCell>
                    {aiProducts.find((p) => p._id === action.productId)?.product_name || '-'}
                  </TableCell>
                  <TableCell>{action.riskName || '-'}</TableCell>
                  <TableCell>{action.title}</TableCell>
                  <TableCell>{action.status}</TableCell>
                  <TableCell>{action.name}</TableCell>
                  <TableCell>
                    {action.users?.length > 0
                      ? action.users.map((user, userIndex) => (
                          <div key={userIndex}>{`${user.firstName} ${user.lastName}`}</div>
                        ))
                      : 'No assigned users'}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default ComplianceActivities;
