import { Box, Button, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import lineSightLogo from 'assets/img/linesight_logo.png';

// eslint-disable-next-line no-unused-vars
const ErrorFallBackComponent = ({ error, resetErrorBoundary }) => {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '70vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <img
        src={lineSightLogo}
        alt="scryb logo"
        style={{
          width: '25rem',
          maxWidth: '80svw',
          height: 'auto',
          margin: '1rem',
          marginBottom: '3rem'
        }}
      />
      <Typography color="grey.600" variant="h5" gutterBottom>
        Sorry, an unexpected error has occurred
      </Typography>
      <Typography color="grey.400" variant="subtitle2" gutterBottom sx={{ maxWidth: '80svw' }}>
        {`${error?.name}: ${error?.message}`}
      </Typography>
      <Stack spacing={2} gap={2} sx={{ pt: 2 }}>
        <Link to="/">Back To Home Page</Link>
        <Button variant="text" onClick={resetErrorBoundary}>
          Try Again
        </Button>
      </Stack>
    </Box>
  );
};

export default ErrorFallBackComponent;
