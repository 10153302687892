import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const ConditionalRoutes = ({ isAllowed = true, redirectTo = {} }) => {
  return isAllowed ? (
    <Suspense>
      <Outlet />
    </Suspense>
  ) : (
    <Navigate to={redirectTo} replace />
  );
};

export default ConditionalRoutes;
