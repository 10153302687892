import {
  FormControl,
  Hidden,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  useMediaQuery,
  useTheme
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2

const PaginationFormControl = ({
  itemsPerPage,
  handleItemsPerPageChange,
  pageCount,
  currentPage,
  handleChangePage
}) => {
  const theme = useTheme();
  const isGreaterMd = useMediaQuery(theme.breakpoints.up('md'));

  if (!isGreaterMd) {
    return null; // If screen size is smaller than 800px, do not render pagination
  }

  return (
    <Hidden smDown>
      <Grid container>
        <Grid xs="auto">
          <FormControl size="small" sx={{ minWidth: 80 }}>
            <InputLabel id="items-per-page-label">Per Page</InputLabel>
            <Select
              labelId="items-per-page-label"
              id="items-per-page-select"
              value={itemsPerPage}
              label="Per Page"
              onChange={handleItemsPerPageChange}
            >
              <MenuItem value={12}>12</MenuItem>
              <MenuItem value={24}>24</MenuItem>
              <MenuItem value={96}>96</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs="auto">
          <Pagination
            count={pageCount}
            page={currentPage}
            onChange={handleChangePage}
            boundaryCount={1}
            siblingCount={1}
          />
        </Grid>
      </Grid>
    </Hidden>
  );
};

export default PaginationFormControl;
