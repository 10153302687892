import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = BASE_URL;
axios.defaults.withCredentials = true;

export const httpService = {
  async get(endpoint, data) {
    return await ajax(endpoint, 'GET', data);
  },
  async post(endpoint, data) {
    return await ajax(endpoint, 'POST', data);
  },
  async put(endpoint, data) {
    return await ajax(endpoint, 'PUT', data);
  },
  async delete(endpoint, data) {
    return await ajax(endpoint, 'DELETE', data);
  }
};

async function ajax(endpoint, method = 'GET', data = null) {
  const res = await axios({
    url: `${endpoint}`,
    method,
    data,
    params: method === 'GET' ? data : null
  });
  return res && res.data ? res.data : res;
}
