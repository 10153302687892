import { useAuth } from 'contexts/auth-context';
import { Suspense, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RootUserRoute = ({ children }) => {
  const { user, navigateToSignIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.email || !user?.token) {
      return navigateToSignIn();
    }
    if (!user?.isRoot) {
      return navigate(`/compliance/${user.orgId}/dashboard`, { replace: true });
    }
  }, [user, navigateToSignIn, navigate]);

  return <Suspense>{user?.isRoot && children}</Suspense>;
};

export default RootUserRoute;
