export const DEFAULT_RISK_MODEL = {
  productId: null,
  productVersion: '',
  reqName: null,
  reqId: null,
  riskName: '',
  riskDescription: '',
  riskCategorization: '',
  riskType: '',
  regulations: [],
  source: 'employee',
  mitigationStatus: 'A',
  mitigationStrategy: '',
  mitigationStrategyAI: '',
  measurableParameters: [],
  validationTestNum: '',
  validationStatus: '',
  verificationTestNum: '',
  verificationStatus: '',
  aiScores: {
    detectability: 0,
    probability: 0,
    severity: 0,
    riskScore: 0,
    detectabilityFm: 0,
    probabilityFm: 0,
    severityFm: 0,
    riskScoreFm: 0
  },
  riskScores: {
    detectability: 0,
    probability: 0,
    severity: 0,
    riskScore: 0,
    detectabilityFm: 0,
    probabilityFm: 0,
    severityFm: 0,
    riskScoreFm: 0
  },
  additionalInfo: {
    reference: '',
    involvesSw: false,
    potentialOrExistingCauses: '',
    possibleHarmCaused: '',
    reviewed: 'NO'
  },
  actions: [],
  documents: [],
  notes: ''
};

export const MITIGATION_STATUS_OPTIONS = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' }
];

export const AI_RISK_SYMBOL = '⃰';

export const DEFAULT_RISK_ACTIVITY_MODEL = {
  activityId: null,
  name: '',
  title: '',
  description: '',
  status: 'To Do',
  users: [],
  isAISelected: false
};
