import { Box, Typography } from '@mui/material';
import lineSightLogo from 'assets/img/linesight_logo.png';
import { Link, useRouteError } from 'react-router-dom';

const ErrorRouteElement = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '70vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <img
        src={lineSightLogo}
        alt="scryb logo"
        style={{
          width: '25rem',
          maxWidth: '80svw',
          height: 'auto',
          margin: '1rem',
          marginBottom: '3rem'
        }}
      />
      <Typography color="grey.600" variant="h5" gutterBottom>
        Sorry, an unexpected error has occurred:
      </Typography>
      <Typography color="error" variant="body1" gutterBottom sx={{ maxWidth: '80svw' }}>
        {error.statusText || error.message}
      </Typography>
      <Link to={'/'}>Go Home</Link>
    </Box>
  );
};

export default ErrorRouteElement;
