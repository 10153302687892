import { useAuth } from 'contexts/auth-context';
import { Suspense, useEffect } from 'react';

const ProtectedRoute = ({ isAllowed = true, children }) => {
  const { user, navigateToSignIn } = useAuth();

  useEffect(() => {
    if (!user?.email || !user?.token) {
      navigateToSignIn();
    }
  }, [user, navigateToSignIn]);

  return <Suspense>{children}</Suspense>;
};

export default ProtectedRoute;
