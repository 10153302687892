import { useQuery } from '@tanstack/react-query';
import { aiProductService } from 'services/ai-product.service';
import { getOrgNameById } from 'lib/utils/utils';

/* This function prioritzes filtering by productId over orgList */
export const useFilteredProductsQuery = (
  selectedOrgList = [],
  productId = null,
  queryOpts = {}
) => {
  const queryOptions = {
    enabled: !!selectedOrgList || !!productId,
    ...queryOpts
  };

  const orgIds = selectedOrgList.map((org) => org._id);

  return useQuery({
    queryKey: ['aiProducts', orgIds, productId],
    queryFn: async () => {
      const data = await aiProductService.getAll();
      const result = data.filter((product) =>
        productId ? product._id === productId : orgIds.some((_id) => _id === product.org_id)
      );
      return result.map((product) => {
        product.org_name = getOrgNameById(product.org_id, selectedOrgList);
        return product;
      });
    },
    ...queryOptions
  });
};
