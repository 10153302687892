import { v4 as uuidv4, validate as validateUUID } from 'uuid';
import styles from 'components/compliance/risk/compliance-risk-index.module.css';
import { AI_RISK_SYMBOL } from 'lib/constants/risks';

const BROWSER_ID_KEY = 'linesight-browser-id';

export const getOrgNameById = (orgId, orgList) => {
  const org = orgList.find((org) => org._id === orgId);
  return org ? org.name : 'Unknown';
};

export const getRiskMqttTopic = (sessionId) => {
  return `linesight/risks/${sessionId}`;
};

export const RISK_LEVEL_NUM = styles['risk-level-num'];

export const getRiskScoreClass = (score) => {
  if (Number(score) >= 1 && Number(score) <= 23) {
    return styles['risk-level-low'];
  } else if (Number(score) >= 24 && Number(score) <= 35) {
    return styles['risk-level-med'];
  } else if (Number(score) >= 36) {
    // && Number(score) <= 125
    return styles['risk-level-high'];
  }
  return styles['risk-level-none'];
};

export const getRiskNumericClass = (prob) => {
  if (Number(prob) >= 1 && Number(prob) <= 6) return styles[`risk-level-${Number(prob)}`];
  if (Number(prob) > 6) return styles['risk-level-high'];
  return styles['risk-level-none'];
};

export const getBrowserId = () => {
  if (
    !!localStorage.getItem(BROWSER_ID_KEY) &&
    validateUUID(localStorage.getItem(BROWSER_ID_KEY))
  ) {
    return localStorage.getItem(BROWSER_ID_KEY);
  } else {
    const newId = uuidv4();
    localStorage.setItem(BROWSER_ID_KEY, newId);
    return newId;
  }
};

export const haNumComparator = (a, b) => {
  const aNum = Number(a.replace(/[^\d.]/g, ''));
  const bNum = Number(b.replace(/[^\d.]/g, ''));
  return aNum - bNum;
};

export const mapRiskValuesWithAI = (risk) => {
  risk._riskScores = {
    detectability:
      risk.riskScores?.detectability > 0
        ? risk.riskScores?.detectability
        : risk.aiScores?.detectability,
    probability:
      risk.riskScores?.probability > 0 ? risk.riskScores?.probability : risk.aiScores?.probability,
    severity: risk.riskScores?.severity > 0 ? risk.riskScores?.severity : risk.aiScores?.severity,
    riskScore:
      risk.riskScores?.riskScore > 0 ? risk.riskScores?.riskScore : risk.aiScores?.riskScore,
    detectabilityFm:
      risk.riskScores?.detectabilityFm > 0
        ? risk.riskScores?.detectabilityFm
        : risk.aiScores?.detectabilityFm,
    probabilityFm:
      risk.riskScores?.probabilityFm > 0
        ? risk.riskScores?.probabilityFm
        : risk.aiScores?.probabilityFm,
    severityFm:
      risk.riskScores?.severityFm > 0 ? risk.riskScores?.severityFm : risk.aiScores?.severityFm,
    riskScoreFm:
      risk.riskScores?.riskScoreFm > 0 ? risk.riskScores?.riskScoreFm : risk.aiScores?.riskScoreFm
  };
  risk._mitigationStrategy = risk.mitigationStrategy
    ? risk.mitigationStrategy
    : risk.mitigationStrategyAI
    ? `${AI_RISK_SYMBOL} ${risk.mitigationStrategyAI}`
    : '';
  return risk;
};
